<template>
  <div class="w-full h-full flex flex-column">
    <Toast position="top-right" />

    <ArtifactInputDialog
      :visible="showArtifactInputDialog"
      :guid="artifactguid"
      :identifier="artifactIdentifier"
      :description="artifactDescription"
      :state="artifactState"
      :plmItem="artifactPlmItem"
      :on-hide="
        () => {
          showArtifactInputDialog = false;
          reloadArtifactsTable();
        }
      "
    >
    </ArtifactInputDialog>
    <div class="m-5 mt-7">
      <ConDataTable
        tableId="ArtifactList"
        title="Artifacts"
        :columns="artifactTableColumns"
        filterDisplay="menu"
        :rowsPerPageOptions="[10, 20, 50]"
        :onReload="fetchArtifactDataAsync"
        :createActionMenu="ArtifactActionMenu"
        :expansionColumns="tableExpansionColumns"
        expansionKey="bundles"
        ref="artifactTableRef"
        globalFilter="identifier|filePath"
      >
      </ConDataTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ReleaseStatus, ArtifactService } from "@/apis/aready-api";
import { ref } from "vue";
import { ConDataTable } from "@congatec/primevue-components-lib";
import Toast from "primevue/toast";
import ArtifactInputDialog from "./ArtifactInputDialog.vue";

const showArtifactInputDialog = ref(false);
const artifactguid = ref();
const artifactDescription = ref();
const artifactIdentifier = ref();
const artifactPlmItem = ref();
const artifactState = ref();
const artifactTableRef = ref<null | typeof ConDataTable>(null);

async function fetchArtifactDataAsync(
  filters: string,
  sorts: string,
  page: number,
  rows: number,
): Promise<any> {
  let res = await ArtifactService.getApiV1Artifacts(
    filters,
    sorts,
    page,
    rows,
  );
  console.log("Resolution: ", res);
  return {
    totalCount: res.totalCount,
    data: res.artifacts,
  };
}

function ArtifactActionMenu(row: any): any {
  return [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        artifactguid.value = row.guid;
        artifactDescription.value = row.description;
        artifactIdentifier.value = row.identifier;
        artifactPlmItem.value = row.plmItem;
        artifactState.value = row.state;
        showArtifactInputDialog.value = true;
      },
    },
    {
      label: "Review",
      icon: "pi pi-file-check",
      disabled: row.state != ReleaseStatus.DRAFT,
      command: () => setStatus(ReleaseStatus.REVIEW, row),
    },
    {
      label: "Release",
      icon: "pi pi-thumbs-up",
      disabled: row.state != ReleaseStatus.REVIEW,
      command: () => setStatus(ReleaseStatus.RELEASE, row),
    },
    {
      label: "Reject",
      icon: "pi pi-thumbs-down",
      disabled: row.state != ReleaseStatus.REVIEW,
      command: () => setStatus(ReleaseStatus.REJECTED, row),
    },
  ];
}

function setStatus(state: ReleaseStatus, row: any) {
  ArtifactService.putApiV1Artifacts(row.guid, {
    identifier: row.identifier,
    description: row.description,
    plmItem: row.plmItem,
  });

  reloadArtifactsTable();
}

function reloadArtifactsTable() {
  artifactTableRef.value?.forceReload();
}

const artifactTableColumns = [
  {
    expander: true,
    field: "expansion",
    header: "",
  },
  {
    field: "identifier",
    header: "Identifier",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "filePath",
    header: "File Path",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "state",
    header: "State",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "plmItem",
    header: "Plm Item",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "fileSize",
    header: "File Size",
    dataType: "numeric",
    isFiltered: true,
    sortable: true,
  },

  {
    field: "guid",
    header: "Guid",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },

  {
    field: "description",
    header: "Description",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },

  {
    field: "fileHash",
    header: "File Hash",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "hashAlgorithm",
    header: "Hash Algorythm",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "actionMenu",
  },
];

const tableExpansionColumns = [
  {
    field: "guid",
    header: "Bundle Guid",
    dataType: "text",
  },
  {
    field: "state",
    header: "Bundle State",
    dataType: "text",
  },
  {
    field: "name",
    header: "Bundle Name",
    dataType: "text",
  },
  {
    field: "version",
    header: "Bundle Version",
    dataType: "text",
  },
];
</script>
<style></style>
