<template>
  <div class="w-full h-full flex flex-column">
    <Toast position="top-right" />
    <ConfirmDialog />

    <BundlesCreateDialog
      :visible="showBundleCreateDialog"
      :guid="bundleguid"
      @close="
        () => {
          showBundleCreateDialog = false;
          reloadBundlesTable();
        }
      "
    ></BundlesCreateDialog>

    <Toolbar>
      <template #start>
        <div class="flex flex-row gap-3">
          <Button
            icon="pi pi-user"
            label="Bundles"
            class="p-button-sm"
            @click="
              (e: any) => {
                toggleBundlesMenu(e);
              }
            "
            aria-haspopup="true"
            aria-controls="overlay_menu"
          />
          <TieredMenu
            ref="bundlesMenu"
            id="overlay_menu"
            :model="bundlesMenuItems"
            :popup="true"
          />
        </div>
      </template>
    </Toolbar>
    <div class="m-5 mt-7">
      <ConDataTable
        tableId="BundleList"
        title="Bundles"
        :columns="bundleTableColumns"
        filterDisplay="menu"
        :rowsPerPageOptions="[10, 20, 50]"
        expansionKey="artifacts"
        :onReload="fetchBundleDataAsync"
        :createActionMenu="createBundleActionMenu"
        :expansionColumns="tableExpansionColumns"
        ref="bundleTableRef"
      >
      </ConDataTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ConDataTable } from "@congatec/primevue-components-lib";
import BundlesCreateDialog from "./BundlesCreateDialog.vue";
import Button from "primevue/button";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import Toolbar from "primevue/toolbar";
import TieredMenu from "primevue/tieredmenu";
import { ref } from "vue";
import { useConfirm } from "primevue/useconfirm";
import {
  ArtifactService,
  BundleService,
  type BundlesListResponse_Bundle,
  ReleaseStatus,
} from "@/apis/aready-api";
import ConfirmDialog from "primevue/confirmdialog";
import { useRoute, useRouter } from "vue-router";
const bundlesMenu = ref();
const showBundleCreateDialog = ref(false);
const selectedBundle = ref<Array<BundlesListResponse_Bundle>>([]);
const selectedBundleBackup = ref<Array<BundlesListResponse_Bundle>>([]);
const bundleTableRef = ref<null | typeof ConDataTable>(null);
const confirm = useConfirm();
const toast = useToast();
const bundleguid = ref();

const router = useRouter();

const bundlesMenuItems = ref([
  {
    label: "Create",
    icon: "pi pi-plus",
    command: () => {
      bundleguid.value = null;
      selectedBundleBackup.value = selectedBundle.value;
      selectedBundle.value = [];
      showBundleCreateDialog.value = true;
    },
  },
]);

const bundleTableColumns = [
  {
    expander: true,
    field: "expansion",
    header: "",
  },
  {
    field: "name",
    header: "Name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "state",
    header: "State",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "version",
    header: "Version",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "guid",
    header: "Guid",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "actionMenu",
  },
];

const tableExpansionColumns = [
  {
    field: "filePath",
    header: "File Path",
    dataType: "text",
  },
  {
    field: "fileName",
    header: "File Name",
    dataType: "text",
  },
  {
    field: "fileSize",
    header: "File Size",
    dataType: "numeric",
  },
  {
    field: "description",
    header: "Description",
    dataType: "text",
  },
  {
    field: "plmItem",
    header: "PLM Item",
    dataType: "text",
  },
  {
    field: "identifier",
    header: "Identifier",
    dataType: "text",
  },
];

async function fetchBundleDataAsync(
  filters: string,
  sorts: string,
  page: number,
  rows: number,
): Promise<any> {
  let res = await BundleService.getApiV1SoftwareBundles1(
    filters,
    sorts,
    page,
    rows,
  );
  return {
    totalCount: res.totalCount,
    data: res.bundles,
  };
}

function toggleBundlesMenu(e: any) {
  bundlesMenu.value.toggle(e);
}

function reloadBundlesTable() {
  bundleTableRef.value?.forceReload();
}

function createBundleActionMenu(row: any): any {
  return [
    {
      label: "Details",
      icon: "pi pi-eye",
      command: () => {
        router.push({ path: `/aready/bundles/${row.guid}` });
      },
    },
    {
      label: "Download",
      icon: "pi pi-download",
      command: () => {
        BundleDownload(row);
      },
    },
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        selectedBundleBackup.value = selectedBundle.value;
        selectedBundle.value = [];
        bundleguid.value = row.guid;
        showBundleCreateDialog.value = true;
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => deleteBundle(row),
    },
    {
      label: "Review",
      icon: "pi pi-file-check",
      disabled: row.state != ReleaseStatus.DRAFT,
      command: () => setStatusIncrease(row),
    },
    {
      label: "Release",
      icon: "pi pi-thumbs-up",
      disabled: row.state != ReleaseStatus.REVIEW,
      command: () => setStatusIncrease(row),
    },
    {
      label: "Reject",
      icon: "pi pi-thumbs-down",
      disabled: row.state != ReleaseStatus.REVIEW,
      command: () => setStatusDecrease(row),
    },
  ];
}

async function setStatusIncrease(row: any) {
  await BundleService.putApiV1SoftwareBundlesSetState(row.guid, {
    guid: row.guid,
    increase: true,
  });
  reloadBundlesTable();
}

async function setStatusDecrease(row: any) {
  await BundleService.putApiV1SoftwareBundlesSetState(row.guid, {
    guid: row.guid,
    increase: false,
  });
  reloadBundlesTable();
}

function deleteBundle(row: any) {
  confirm.require({
    header: "Delete Confirmation",
    icon: "pi pi-info-circle",
    acceptClass: "p-button-info",
    //TODO: implement option to see a list of items that will be delted
    message: "Are you sure you want to delete this Item",
    accept: async () => {
      try {
        await BundleService.deleteApiV1SoftwareBundles({
          guid: row.guid,
        });
        toast.add({
          severity: "success",
          summary: "Delete",
          detail: "Item deleted",
          life: 3000,
        });

        reloadBundlesTable();
      } catch (ex: any) {
        toast.add({
          severity: "error",
          summary: "Error!",
          detail: "An error occured while deleting: " + ex.message,
          life: 3000,
        });
      }
    },
  });
}

const BundleDownload = async (row: any) => {
  row.artifacts.forEach(async (artifact: { guid: string }) => {
    let result = await ArtifactService.getApiV1ArtifactsUrl(artifact.guid);
    window.open(result.objectUrl!);
  });
};
</script>
