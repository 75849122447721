<template>
  <ConfirmDialog group="ScreeningStatus" class="max-w-30rem" />
  <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" :header="headerText"
    position="top" style="max-width: 80%; min-width: 80%; max-height: 80%;">
    <template #default>
      <div class="card flex justify-content-left">
        <div class="flex flex-column gap-5 w-full">
          <div class="flex flex-row mt-3 gap-5 w-full">
            <div class="w-full">
              <!-- <label >Private Person </label> -->
              <label>Company Visitor: </label>
              <InputSwitch v-model="isCompanyVisitor" />
            </div>
          </div>
          <div class="flex flex-row gap-5 w-full">
            <div class="w-full">
              <label for="firstname">Firstname <span>*</span></label>
              <InputText id="firstname" v-model="state.Firstname" :value="state.Firstname"
                placeholder="Enter the firstname for the new Visitor" class="w-full" :class="{ 'input-invalid': v$.Firstname.$invalid && v$.Firstname.$error, 'h-3rem': true }"/>
                <span v-if="v$.Firstname.$error">{{
                v$.Firstname.$errors[0].$message }}
              </span>
            </div>
            <div class="w-full">
              <label for="lastname">Lastname <span>*</span></label>
              <InputText id="lastname" v-model="state.Lastname" placeholder="Enter the lastname for the new Visitor"
                class="w-full" :class="{ 'input-invalid': v$.Lastname.$invalid && v$.Lastname.$error, 'h-3rem': true }"/>
                <span v-if="v$.Lastname.$error">{{
                v$.Lastname.$errors[0].$message }}
              </span>
            </div>
          </div>
          <div class="flex flex-row gap-5 w-full">
            <div class="w-full">
              <label for="email">Email</label>
              <InputText id="email" v-model="state.Email" placeholder="Enter the email for the new Visitor"
                class="w-full" :class="{ 'input-invalid': v$.Email.$invalid && v$.Email.$error, 'h-3rem': true }" />
                <span v-if="v$.Email.$error">{{
                v$.Email.$errors[0].$message }}
              </span>
            </div>
            <div class="w-full">
              <label for="company">Company<span v-if="isCompanyVisitor">*</span></label>
              <InputText id="company" v-model="state.Company" placeholder="Enter the company for the new Visitor"
                class="w-full" :class="{ 'input-invalid': v$.Company.$invalid && v$.Company.$error, 'h-3rem': true }"/>
                <span v-if="v$.Company.$error">{{
                  v$.Company.$errors[0].$message }} 
                </span>
            </div>
          </div>
          <div class="flex flex-row gap-5 w-full">
            <div class="w-full">
              <label for="postalcode">Postalcode<span v-if="isCompanyVisitor">*</span></label>
              <InputText id="postalcode" v-model="state.Postalcode" placeholder="Enter the postalcode for the new Visitor"
                class="w-full" :class="{ 'input-invalid': v$.Postalcode.$invalid && v$.Postalcode.$error, 'h-3rem': true }" />
                <span v-if="v$.Postalcode.$error">{{
                  v$.Postalcode.$errors[0].$message }} 
                </span>
            </div>
            <div class="w-full">
              <label for="city">City<span v-if="isCompanyVisitor">*</span></label>
              <InputText id="city" v-model="state.City" placeholder="Enter the city for the new Visitor" 
              class="w-full" :class="{ 'input-invalid': v$.City.$invalid && v$.City.$error, 'h-3rem': true }" />
              <span v-if="v$.City.$error">{{
                v$.City.$errors[0].$message }} 
              </span>
            </div>
          </div>
          <div class="flex flex-row gap-5 w-full">
            <div class="w-full">
              <label for="street">Street<span v-if="isCompanyVisitor">*</span></label>
              <InputText id="street" v-model="state.Street" placeholder="Enter the street of the new Visitor" 
              class="w-full" :class="{ 'input-invalid': v$.Street.$invalid && v$.Street.$error, 'h-3rem': true }" />
              <span v-if="v$.Street.$error">{{
                v$.Street.$errors[0].$message }} 
              </span>
            </div>
            <div class="w-full flex flex-column">
              <label for="country">Country <span>*</span></label>
              <Dropdown id="country" filter v-model="state.Country" :options="countriesList" optionLabel="name"
                optionValue="code" placeholder="Select a Country" class="w-full md:w-14rem" :class="{ 'input-invalid': v$.Country.$invalid && v$.Country.$error, 'h-3rem': true }"/>
                <span v-if="v$.Country.$error">{{
                v$.Country.$errors[0].$message }}
              </span>
            </div>
          </div>

          <div class="">
            <label>Visibility</label>
            <Dropdown v-model="access" :options="accessLevels" placeholder="Visibility" class="w-full" />
          </div>

          <div>
            <label>Substitutes</label>
            <AutoComplete dropdown class="w-full" v-model="userQuery" :suggestions="usersFound" optionLabel="identifier"
              @item-select="selectUser" />
            <MultiSelect v-model="substitutes" :options="substitutes" display="chip" placeholder="Select Substitutes"
              optionLabel="email" class="w-full mt-3" />
          </div>
        </div>


      </div>
    </template>
    <template #footer>
      <div class="flex flex-row align-items-end justify-content-between">
        <Button class="p-button-text" label="Cancel" :onClick="() => {
          dialogVisible = false;
        }" />

        <!-- <ConMessages class=""
          v-if="v$.Firstname.$invalid || v$.Lastname.$invalid || v$.Email.$invalid || v$.Country.$invalid"
          severity="error">
          <template #messageContent>
              {{ v$.$errors[0].$property + " " + v$.$errors[0].$message }}
          </template>
        </ConMessages> -->

        <Button class="p-button-primary" label="Save" :onClick="async () => {
          if (props.visitor && !$props.copy) {
            await dispatchEditVisitorAsync({
              id: $props.visitor.id,
              firstName: state.Firstname,
              lastName: state.Lastname,
              postalCode: state.Postalcode,
              street: state.Street,
              city: state.City,
              email: state.Email,
              company: state.Company,
              country: state.Country,
              substituteUserIds: substitutes.map((x: any) => x.id),
              accessLevel: access
            })

            return;
          }

          await dispatchCreateVisitorAsync({
            firstname: state.Firstname,
            lastname: state.Lastname,
            email: state.Email,
            company: state.Company,
            postalcode: state.Postalcode,
            city: state.City,
            street: state.Street,
            country: state.Country,
            substituteUserIds: substitutes.map((x: any) => x.id),
            accessLevel: access
          } as Partial<CreateVisitorRequest>)
        }" />
      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { type EditVisitorRequest, VisitorService, UserService, AccessLevel, type CreateVisitorRequest, ScreeningStatus } from '@/apis/neptune-api';
import MultiSelect from 'primevue/multiselect'
import AutoComplete from 'primevue/autocomplete'
import { delay, useDelayTimer } from '@congatec/primevue-components-lib';
import Dropdown from 'primevue/dropdown';
import { useVuelidate } from '@vuelidate/core'
import { required, email, requiredIf } from '@vuelidate/validators'
import { countries } from "countries-list"
import InputSwitch from 'primevue/inputswitch';
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";

const props = defineProps<{
  visitor?: any,
  copy?:boolean,
  visible: boolean,
  onHide: (edited: boolean) => any
}>();

const state = ref({
  Firstname: '',
  Lastname: '',
  Email: '',
  Country: '',
  Company: '',
  Postalcode: '',
  Street: '',
  City: '',
});

const confirm = useConfirm();
const dialogVisible = ref(false);
// const firstname = ref("");
// const lastname = ref("");
// const postalcode = ref("");
// const street = ref("");
// const city = ref("");
// const email = ref("");
//const company = ref("");
//const country = ref("");
const substitutes = ref<any>([]);
const access = ref(AccessLevel.DEPARTMENT);

const accessLevels = [AccessLevel.DEPARTMENT, AccessLevel.PUBLIC, AccessLevel.PRIVATE];
const userQuery = ref<any>("");
const usersFound = ref<any[]>([]);
const userQueryTimer = useDelayTimer();
let headerText = "Create a new Visitor";
const isCompanyVisitor = ref(true);

//const selectedCountry = ref();
const countriesList = getCountriesList(); //getCountries();

// ref([
//     { name: 'Australia', code: 'AU' },
//     { name: 'Brazil', code: 'BR' },
//     { name: 'China', code: 'CN' },
//     { name: 'Egypt', code: 'EG' },
//     { name: 'France', code: 'FR' },
//     { name: 'Germany', code: 'DE' },
//     { name: 'India', code: 'IN' },
//     { name: 'Japan', code: 'JP' },
//     { name: 'Spain', code: 'ES' },
//     { name: 'United States', code: 'US' }
// ]);


const rules = {
  Firstname: { required },
  Lastname: { required },
  Country: { required },
  Email: { email },
  Company: { required: requiredIf(() => isCompanyVisitor.value) },
  Postalcode: { required: requiredIf(() => isCompanyVisitor.value) },
  Street: { required: requiredIf(() => isCompanyVisitor.value) },
  City: { required: requiredIf(() => isCompanyVisitor.value) },
  //description: { required },
}
const v$ = useVuelidate(rules, state);


watch(() => props.visible, (nv) => {
  console.log("VISIBLE: ", nv);
  console.log("Prop visitor", props.visitor)
  if (nv) {
    dialogVisible.value = nv;
  }

  if (nv && props.visitor ) {
    headerText = "Edit Visitor"
    state.value.Firstname = props.visitor.firstName;
    state.value.Lastname = props.visitor.lastName;
    state.value.Postalcode = props.visitor.postalCode;
    state.value.Street = props.visitor.street;
    state.value.City = props.visitor.city;
    state.value.Email = props.visitor.email;
    state.value.Company = props.visitor.company;
    state.value.Country = props.visitor.country;
    substitutes.value = props.visitor.substitutes;
    access.value = props.visitor.access;
  }
  if(props.visitor && props.copy){
    headerText = "Copy Visitor";
    state.value.Firstname = "";
    state.value.Lastname = "";
    state.value.Email = "";
  }
}, { deep: true, immediate: true });

watch(() => dialogVisible.value, (nv) => {
  if (nv != null && !nv) {
    v$.value.$reset();
    state.value.Firstname = "";
    state.value.Lastname = "";
    state.value.Postalcode = "";
    state.value.Street = "";
    state.value.City = "";
    state.value.Email = "";
    state.value.Company = "";
    state.value.Country = "";
    isCompanyVisitor.value = true;
    substitutes.value = [];
    access.value = AccessLevel.DEPARTMENT;
    props.onHide(true);
  }

})

watch(userQuery, async () => {
  delay(userQueryTimer, async () => {
    if (!userQuery.value) {
      usersFound.value = [];
      return;
    }

    let query = userQuery.value;
    usersFound.value = (await UserService.postApiV1UserFindUser(query)).users || [];

    for (let user of usersFound.value) {
      if (user.email) {
        user.identifier = user.email;
      } else {
        user.identifier = `${user.firstName} ${user.lastName}`
      }
    }
  });

});

const selectUser = () => {
  substitutes.value.push(userQuery.value);
  userQuery.value = '';
}

async function dispatchEditVisitorAsync(EditVisitorRequest: EditVisitorRequest) {
  await v$.value.$reset();
  await v$.value.$validate(); // checks all inputs
  if (v$.value.$error) {
    return;
  }
  await VisitorService.putApiV1VisitorEdit(EditVisitorRequest);
  dialogVisible.value = false;
}

async function dispatchCreateVisitorAsync(createVisitorRequest: CreateVisitorRequest) {
  console.log("Visitor Create: ", createVisitorRequest)
  await v$.value.$reset();
  await v$.value.$validate() // checks all inputs
  if (v$.value.$error) {
    return;
  }
  let res = await VisitorService.postApiV1VisitorCreate(createVisitorRequest);
  if (res.status == ScreeningStatus.LISTED) {
    confirm.require({
      group: "ScreeningStatus",
      header: "Negative Screening Status",
      icon: "pi pi-info-circle",
      acceptClass: "hidden",
      message: `The visitor screening result was negativ. 
      Please provide further information about the visitor (e.g. address)
      and screen again manually or contact ECC (export-control@congatec.com).`,
      rejectLabel: "Close",
      accept: async () => {
      },
    });
  }

  dialogVisible.value = false;
}

// function getCountries(lang = 'en') {
//     const A = 65
//     const Z = 90
//     const countryName = new Intl.DisplayNames([lang], { type: 'region' });
//     const countries: any[] = []
//     for(let i=A; i<=Z; ++i) {
//         for(let j=A; j<=Z; ++j) {
//             let code = String.fromCharCode(i) + String.fromCharCode(j)
//             let name = countryName.of(code)
//             if (code !== name ) { //&& !countries.some(e => e.name === name)) {
//                 countries.push({code: code, name: name });
//             }
//         }
//     }
//     return countries.sort((p1,p2) => (p1.name > p2.name) ? 1 : (p1.name < p2.name) ? -1 : 0);
// }

function getCountriesList() {
  const result: any[] = []
  const countryCodes = Object.keys(countries);
  countryCodes.map(code => result.push({ code: code, name: (countries as any)[code].name }));
  return result.sort((p1, p2) => (p1.name > p2.name) ? 1 : (p1.name < p2.name) ? -1 : 0);
}


</script>
<style scoped>
.input-invalid {
    border: 1px solid var(--primary-color);
}

.input-invalid:hover {
    border: 1px solid var(--primary-color) !important;
}
span {
    color: var(--primary-color);
    font-weight: 600;
}
</style>
