import { to } from "@congatec/primevue-components-lib";
import AssetsList from "../components/AssetsList.vue";
import AssetsDetails from "../components/AssetsDetails.vue";

const title = "Luna";

const lunaRoutes = [
  {
    path: "/luna/assets",
    name: "AssetsList",

    component: AssetsList,
    meta: {
      breadcrumb: [{ title: title }, { label: "Assets" }],
    },
  },
  {
    path: "/luna/assets/:id",
    name: "Asset Details",

    component: AssetsDetails,
    props: (route: any) => ({
      id: route.params.id,
    }),
    meta: {
      breadcrumb: [
        { title: title, command: to("/luna/assets") },
        { label: "Asset Details" },
      ],
    },
  },
];

export default lunaRoutes;
