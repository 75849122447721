<template>
  <FileDownload
    :visible="showDownloadDialog"
    :files="selectedNodes"
    :on-hide="
      () => {
        showDownloadDialog = false;
      }
    "
  ></FileDownload>

  <FileMetadata
    :visible="showMetadataDialog"
    :file="editMetadataKey"
    :on-hide="
      () => {
        showMetadataDialog = false;
      }
    "
  ></FileMetadata>

  <div class="flex flex-column gap-5 m-5 mt-7">
    <div class="flex flex-row align-items-start gap-2 mb-6">
      <Button
        type="button"
        icon="pi pi-plus"
        label="Expand All"
        @click="expandAll"
      />
      <Button
        type="button"
        icon="pi pi-minus"
        label="Collapse All"
        @click="collapseAll"
      />
      <div class="flex flex-column align-self-end">
        <Button
          :disabled="Object.keys(selectedKey).length == 0"
          type="button"
          icon="pi pi-file-download"
          label="Download"
          @click="onDownload"
        />
      </div>
    </div>
    <Tree
      v-model:expandedKeys="expandedKeys"
      :value="nodes"
      v-model:selectionKeys="selectedKey"
      selectionMode="checkbox"
      class="w-full md:w-[30rem]"
    >
      <template #default="slotProps">
        <p>{{ slotProps.node.label }}</p>
      </template>
      <template #file="slotProps">
        <div class="flex flex-nowrap gap-2 align-items-center">
          <p>{{ slotProps.node.label }}</p>
          <Button
            icon="pi pi-file-edit"
            rounded
            text
            aria-label="Edit"
            @click.stop="editMetadata(slotProps.node.key)"
          ></Button>
        </div>
      </template>
    </Tree>
    <!-- @nodeSelect="onNodeSelect" @node-unselect="onNodeUnSelect" -->
  </div>
</template>
<script setup lang="ts">
import { type Ref, ref, onMounted } from "vue";
import Button from "primevue/button";
import { ArtifactService } from "@/apis/aready-api";
import Tree from "primevue/tree";
import type { TreeNode } from "primevue/treenode";
import { useToast } from "primevue/usetoast";
import FileDownload from "./FileDownload.vue";
import FileMetadata from "./FileMetadata.vue";

const nodes: Ref<Array<TreeNode>> = ref([]);
const expandedKeys: Ref<any> = ref({});
const selectedKey: Ref<any> = ref({});
const selectedNodes: Ref<{ guid: string, name: string }[]> = ref([]);
const toast = useToast();
const showDownloadDialog = ref<boolean>(false);
const showMetadataDialog = ref<boolean>(false);
const editMetadataKey = ref<string>();

onMounted(async () => {
  await onInit();
});

const onInit = async () => {
  let response = await ArtifactService.getApiV1Artifacts() //StorageService.getApiV1StoragesDirectories("");
  let result: any[] = [];
  let level = { result };
  response.artifacts?.forEach((entry) => {
    entry.filePath!.split("/").reduce((r: any, name, i, a) => {
      if (!r[name]) {
        r[name] = { result: [] };
        r.result.push({ name, guid: entry.guid, size: entry.fileSize, children: r[name].result });
      }

      return r[name];
    }, level);
  });
  //console.log(result)
  result.forEach((entry) => addNodes(entry));
};

const addNodes = (entry: any) => {
  if (entry.children.length > 0) {
    let node: TreeNode = {
      key: entry.name,
      label: entry.name,
      icon: "pi pi-folder",
      children: [],
    };
    entry.children.forEach((child: any) => {
      let childNode = addChildNode(entry, child);
      if (childNode != null) {
        node.children?.push(childNode);
      } else {
        node.selectable = false;
      }
    }, nodes.value.push(node));
  } else {
    let node: TreeNode = {
      key: entry.name,
      label: entry.name + " (" + entry.size/1000 + "KB)",
      data: entry.guid,
      icon: "pi pi-file",
      type: "file",
    };
    nodes.value.push(node);
  }
};

const addChildNode = (parent: any, child: any) => {
  if (child.children && child.children.length > 0) {
    let node: TreeNode = {
      key: (parent.name || parent.key) + "/" + child.name,
      label: child.name,
      icon: "pi pi-folder",
      children: [],
    };
    child.children.forEach((child: TreeNode) => {
      let add = addChildNode(node, child);
      if (add != null) {
        node.children?.push(add);
      }
    });
    return node;
  } else if (child.name != "") {
    let node: TreeNode = {
      key: (parent.name || parent.key) + "/" + child.name,
      label: child.name + " (" + child.size/1000 + " KB)",
      data: child.guid,
      icon: "pi pi-file",
      type: "file",
    };
    return node;
  }
  return null;
};

const expandAll = () => {
  for (let node of nodes.value) {
    expandNode(node);
  }

  expandedKeys.value = { ...expandedKeys.value };
};

const collapseAll = () => {
  expandedKeys.value = {};
};

const expandNode = (node: TreeNode) => {
  if (node.key && node.children && node.children.length) {
    expandedKeys.value[node.key] = true;

    for (let child of node.children) {
      expandNode(child);
    }
  }
};

const onDownload = () => {
  selectedNodes.value = [];
  Object.keys(selectedKey.value).forEach((key) => {
    let node: TreeNode | null = findNode(nodes.value, key);
    if (node != null && (node as TreeNode).key != null) {
      selectedNodes.value.push({guid: (node as TreeNode).data!, name: (node as TreeNode).key!});
      return;
    } else {
      // continue
      return;
    }
  });
  //console.log("selectedNodes: ", selectedNodes.value);
  showDownloadDialog.value = true;
};

const findNode = (nodeList: TreeNode[], key: string) => {
  let resultNode: TreeNode | null = null;
  nodeList.forEach((node) => {
    if (resultNode != null) return;
    if (node.key == key) {
      if (node.children == null || node.children.length == 0) {
        // file
        resultNode = node;
      } else {
        // folder => do not add;
        return;
      }
    } else if (node.children != null && node.children.length > 0) {
      resultNode = findNode(node.children, key);
    }
  });

  return resultNode;
};

const editMetadata = (key: any) => {
  // console.log("editMetadata: ", key);
  editMetadataKey.value = key;
  showMetadataDialog.value = true;
};
</script>
<style></style>
