import FileUpload from "../components/FileUpload.vue";
import DirectoryList from "../components/DirectoryList.vue";
import ArtifactList from "../components/artifact/ArtifactList.vue";
import BundlesList from "../components/bundle/BundlesList.vue";
import FileList from "../components/FileList.vue";
import BundlesDetails from "../components/bundle/BundlesDetails.vue";
import { to } from "@congatec/primevue-components-lib";

const areadyRoutes = [
  {
    path: "/aready/file-upload",
    name: "FileUpload",
    component: FileUpload,
    meta: {
      breadcrumb: [{ title: "aReady" }, { label: "FileUpload" }],
    },
  },
  {
    path: "/aready/bundles",
    name: "BundlesList",
    component: BundlesList,
    meta: {
      breadcrumb: [{ title: "aReady" }, { label: "Bundles List" }],
    },
  },
  {
    path: "/aready/file-list",
    name: "FileList",
    component: FileList,
    meta: {
      breadcrumb: [{ title: "aReady" }, { label: "DirectoryList" }],
    },
  },

  {
    path: "/aready/artifact-list",
    name: "ArtifactList",
    component: ArtifactList,
    meta: {
      breadcrumb: [{ title: "aReady" }, { label: "ArtifactList" }],
    },
  },
  {
    path: "/aready/bundles/:guid",
    name: "BundlesDetails",
    component: BundlesDetails,
    props: (route: any) => ({
      guid: route.params.guid,
    }),
    meta: {
      breadcrumb: [
        { title: "aReady", command: to("/aready/home") },
        { label: "Bundles List", command: to("/aready/bundles") },
        { label: "Bundles Details" },
      ],
    },
  },
];

export default areadyRoutes;
