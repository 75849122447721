<template>
  <ConfirmDialog group="QuickCreateScreeningStatus" class="max-w-30rem" />
  <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" :header="headerText"
    position="top" style="max-width: 80%; min-width: 60%; max-height: 80%;">
    <template #default>
      <div class="flex flex-column">
        <div class="flex flex-row justify-content-center gap-6 w-full">
          <div class="flex flex-column mt-6 gap-2">

            <div v-for="(x, index) in state.quickVisitors" :key="x.id" class="flex flex-column gap-2">
              <div class="w-full flex flex-column">
                <label>First/Last Name <span>*</span><span v-if="v$.quickVisitors.$errors[0]">{{
                  (v$.quickVisitors.$errors[0].$message as any)[index][0] }}
                  </span></label>
                <InputGroup :id="'inputGroup' + index">
                  <InputText :id="'firstName' + index" v-model="state.quickVisitors[index].firstName"
                    placeholder="First Name" :value="state.quickVisitors[index].firstName"
                    :class="{ 'input-invalid': v$.quickVisitors.$invalid && v$.quickVisitors.$each.$response.$errors[index].firstName.length, 'h-3rem': true }" />
                  <!-- <span v-if="v$.quickVisitors.$errors[0]">{{
                    v$.quickVisitors.$errors[0].$message[index][0] }}
                  </span> -->
                  <div class="flex flex-row gap-2">
                    <InputText :id="'lastName' + index" v-model="state.quickVisitors[index].lastName"
                      placeholder="Last Name" :value="state.quickVisitors[index].lastName"
                      :class="{ 'input-invalid': v$.quickVisitors.$invalid && v$.quickVisitors.$each.$response.$errors[index].lastName.length, 'h-3rem': true }" />
                    <!-- <span v-if="v$.quickVisitors.$each.$response">{{
                      v$.quickVisitors.$errors[0].$message }}
                    </span> -->
                    <!-- <InputText placeholder="Email" /> -->
                    <!-- <InputGroupAddon>
                </InputGroupAddon> -->
                    <div class="flex flex-row gap-1">
                      <Button :id="'btnRemove' + index" icon="pi pi-user-minus" @click="removeIndex(x.id)"
                        class="userbutton" text />
                      <Button icon="pi pi-user-plus" @click="addIndex()" class="userbutton" text />
                    </div>
                  </div>
                </InputGroup>
              </div>
            </div>

            <div class="flex flex-column w-4 mt-3">
              <div class="flex flex-row gap-2">
                <div class="w-full flex flex-column gap-2">
                  <div class="w-full">
                    <!-- <label >Private Person </label> -->
                    <label>Company Visitor: </label>
                    <InputSwitch v-model="isCompanyVisitor" />
                  </div>
                  <div class="w-full">
                    <label for="company">Company<span v-if="isCompanyVisitor">*</span></label>
                    <InputText id="company" v-model="state.Company" placeholder="Enter the company for the new Visitor"
                      class="w-exact"
                      :class="{ 'input-invalid': v$.Company.$invalid && v$.Company.$error, 'h-3rem': true }" />
                    <span v-if="v$.Company.$error">{{
                      v$.Company.$errors[0].$message }}
                    </span>
                  </div>
                  <div class="w-full">
                    <label for="postalcode">Postalcode<span v-if="isCompanyVisitor">*</span></label>
                    <InputText id="postalcode" v-model="state.Postalcode"
                      placeholder="Enter the postalcode for the new Visitor" class="w-exact"
                      :class="{ 'input-invalid': v$.Postalcode.$invalid && v$.Postalcode.$error, 'h-3rem': true }" />
                    <span v-if="v$.Postalcode.$error">{{
                      v$.Postalcode.$errors[0].$message }}
                    </span>
                  </div>
                  <div class="w-full">
                    <label for="city">City<span v-if="isCompanyVisitor">*</span></label>
                    <InputText id="city" v-model="state.City" placeholder="Enter the city for the new Visitor"
                      class="w-exact" :class="{ 'input-invalid': v$.City.$invalid && v$.City.$error, 'h-3rem': true }" />
                    <span v-if="v$.City.$error">{{
                      v$.City.$errors[0].$message }}
                    </span>
                  </div>
                  <div class="w-full">
                    <label for="street">Street<span v-if="isCompanyVisitor">*</span></label>
                    <InputText id="street" v-model="state.Street" placeholder="Enter the street of the new Visitor"
                      class="w-exact"
                      :class="{ 'input-invalid': v$.Street.$invalid && v$.Street.$error, 'h-3rem': true }" />
                    <span v-if="v$.Street.$error">{{
                      v$.Street.$errors[0].$message }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-column">
              <div class="flex flex-row gap-2">
                <div class="w-full flex flex-column">
                  <label for="country">Country <span>*</span></label>
                  <Dropdown id="country" filter v-model="state.country" :options="countriesList" optionLabel="name"
                    optionValue="code" placeholder="Select a Country" @change="changeCountry($event)" class="w-exact"
                    :class="{ 'input-invalid': v$.country.$invalid && v$.country.$error, 'h-3rem': true }" />
                  <span v-if="v$.country.$error">{{
                    v$.Country.$errors[0].$message }}
                  </span>
                </div>
              </div>
            </div>
            <div class="flex flex-column">
              <div class="flex flex-row gap-2">
                <div class="w-full flex flex-column">
                  <label for="branchOffice">Branch Office</label>
                  <Dropdown id="branchOffice" v-model="branchOffice" :options="branchOffices" optionValue="value"
                    optionLabel="name" placeholder="Select Branch Office" @change="changeBranchOffice($event)"
                    class="w-exact" />
                </div>
              </div>
            </div>
            <div class="w-full flex flex-column">
              <label class="w-exact" for="room">Meeting Room</label>
              <div class="flex flex-row gap-4 w-exact">
                <Dropdown @change="() => { showReservedMessage = false }" class="w-9" id="room" v-model="selectedRoom"
                  :options="rooms!" optionValue="emailAdress" optionLabel="displayName" showClear
                  placeholder="Select a Meeting Room" />
                <Button class="w-3 text-sm" label="Check availability" @click="() => {
                  checkMeetingRoomAvailability();
                }" />
              </div>
            </div>
            <div class="flex flex-row">
              <Message class="mb-0" v-if="showReservedMessage" :severity="reservedMessageSeverity">{{ reservedMessageText }}
              </Message>
            </div>

          </div>
          <!-- <div class="flex flex-column gap-2 align-items-center justify-content-center">
          <label v-if="props.visit == null" for="visitorBox">There will be a visit created for the following Visitors: </label>
          <label v-else for="visitorBox">There will be a visit edited for the following Visitors: </label>
          <ListBox class="w-auto text-sm" id="visitorBox" :options="visitorData" disabled optionLabel="data"></ListBox>
        </div> -->
          <div class="flex flex-column gap-2 align-items-center gap-3">
            <TabMenu :model="tabMenuItems" activeItem="{{  active Tab }}" @chang="onTabChange"></TabMenu>
            <div v-if="activeTab === 'singleDay'">
              <Calendar v-model="state.scheduledCheckInOutDates" placeholder="Select Single/Multiple Visit Day(s)"
                dateFormat="dd.M.yy" hourFormat="12" inline selectionMode="multiple" />
            </div>
            <div v-if="activeTab === 'multipleDays'">
              <Calendar v-model="scheduledStartEndDates" placeholder="Select Range of Visit Days" dateFormat="dd.M.yy"
                hourFormat="12" inline selectionMode="range" />
            </div>
            <div v-for="(x, index) in state.scheduledCheckInOutDates" :key="x.toString()"
              class="flex flex-row gap-3 w-exact">
              <label class="flex align-items-center w-3" :for="'calendar-CheckIn' + index">{{
                state.scheduledCheckInOutDates[index].toLocaleDateString() }}</label>
              <Calendar :id="'calendar-CheckIn' + index" placeholder="Check In Time"
                v-model="state.scheduledCheckInTime[index]" timeOnly hourFormat="12" @vue-mounted="addCheckInTime()"
                @vue-unmounted="removeCheckInTime(index)" />
              <Calendar :id="'calendar-CheckOut' + index" placeholder="Check Out Time"
                v-model="state.scheduledCheckOutTime[index]" timeOnly hourFormat="12" />
            </div>
          </div>

          <!-- <div v-if="props.visit != null" class="flex justify-content-center">
              <Checkbox v-model="editForAllAttendees" inputId="editForAllAttendees" :binary="true" />
              <label for="editForAllAttendees" class="ml-2"> Edit Event for all Attendees </label>
            </div> -->


        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-1 align-items-end justify-content-between">
        <Button class="p-button-text" label="Cancel" @click="() => {
          dialogVisible = false;


        }" />

        <ConMessages class="" v-if="v$.scheduledCheckInOutDates.$invalid" severity="error">
          <template #messageContent>
            {{ "Check-In Date " + v$.$errors[0].$message }}
          </template>
        </ConMessages>
        <ConMessages class="" v-else-if="v$.scheduledCheckInTime.$invalid" severity="error">
          <template #messageContent>
            {{ "Check-In Time " + v$.$errors[0].$message }}
          </template>
        </ConMessages>
        <ConMessages class="" v-else-if="v$.scheduledCheckOutTime.$invalid" severity="error">
          <template #messageContent>
            {{ "Check-Out Time " + v$.$errors[0].$message }}
          </template>
        </ConMessages>

        <Button class="p-button-primary" label="Save" :onClick="async () => {
          await dispatchCreateVisitAsync({
            scheduledCheckInOutDates: state.scheduledCheckInOutDates.map((x, index) => { x.setHours(state.scheduledCheckInTime[index].getHours(), state.scheduledCheckInTime[index].getMinutes(), 0); return x.toString() }),
            // scheduledCheckInOutDates: state.scheduledCheckInOutDates.map((x) => {
            //   var date = new Date(x);
            //   return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds())).toISOString()
            // }),
            scheduledCheckInTime: state.scheduledCheckInTime.map((x) => { return convertToUTCDateString(x.toString()) }),
            scheduledCheckOutTime: state.scheduledCheckOutTime.map((x) => { return convertToUTCDateString(x.toString()) }),
            meetingRoom: rooms!.filter(room => {
              return room.emailAdress === selectedRoom
            })[0],
            branchOffice: branchOffice,
            company: state.Company,
            postalCode: state.Postalcode,
            city: state.City,
            street: state.Street,
            country: state.country,
            visitor: state.quickVisitors.map((x: any) => {
              return {
                firstName: x.firstName,
                lastName: x.lastName
              }
            }),
          })
        }" />
      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from 'primevue/dialog'
import { type Ref, ref, watch, onMounted } from 'vue';
import Button from 'primevue/button';
import { VisitService, VisitorService, type ListVisitorResponse_Visitors, MicrosoftGraphService, type EditVisitRequest, type ListVisitResponse_Visits, UserService, type Room, type QuickCreateVisitRequest, type ListPlacesResponse, ScreeningStatus } from '@/apis/neptune-api';
import Calendar from 'primevue/calendar';
import TabMenu from 'primevue/tabmenu'
import Dropdown from 'primevue/dropdown';
import { ConDateTimeHelper, ConMessages, delay, useDelayTimer } from '@congatec/primevue-components-lib';
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, requiredIf } from '@vuelidate/validators'
import InputGroup from 'primevue/inputgroup';
import InputText from 'primevue/inputtext';
import { countries } from "countries-list"
import { useVisitStore } from "./store/VisitStore"
import Message from 'primevue/message';
import InputSwitch from 'primevue/inputswitch';
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";

// Dataelements (constants / variables) area
const visitStore = useVisitStore();

const state = ref({
  quickVisitors: [{ id: 1, firstName: "", lastName: "" }],
  country: visitStore.country,
  scheduledCheckInOutDates: Array<Date>(new Date()),
  scheduledCheckInTime: Array<Date>(new Date()),
  scheduledCheckOutTime: Array<Date>(new Date(Date.now() + 1 * (60 * 60 * 1000))),
  Company: '',
  Postalcode: '',
  Street: '',
  City: '',
});

const confirm = useConfirm();
const dialogVisible = ref(false);
const visitorData = ref([{}]);
//const visitorIdList = ref<number[]>([]);
const activeTab = ref('singleDay');
// const scheduledCheckInTime = ref();
// const scheduledCheckOutTime = ref();
// const scheduledCheckInOutDates = ref<string[]>([]);
const scheduledStartEndDates = ref<Date[]>([]);
const scheduledStartEndDate = ref<Date>(new Date());
//const company = ref("");
const isCompanyVisitor = ref(true);

const branchOffice: Ref<string | null | undefined> = ref(visitStore.branchOffice);
//const country = ref(visitStore.country);
const countriesList = getCountriesList();
//const substituteIds = ref();
const visitorIds = ref();
const editForAllAttendees = ref(true);
const tabMenuItems = [
  { label: 'Single/ Multiple Days', icon: 'pi pi-calendar', command: () => setActiveTab('singleDay') },
  { label: 'Range of Days', icon: 'pi pi-calendar-plus', command: () => setActiveTab('multipleDays') },
]
//props definition
const props = defineProps<{
  visible: boolean,
  // visitors?: ListVisitorResponse_Visitors[],
  // visit?: ListVisitResponse_Visits,
  onHide: (created: boolean) => any
}>();

let headerText = "Create a new Visit";
const visitorCount = ref(1);
//const quickVisitors = ref([{ id: 1, firstName: "", lastName: "" }]);


const selectedRoom = ref();
// DONE get rooms from GraphAPI
let rooms: Ref<Room[] | null | undefined> = ref([]);
// const rooms = ref([
//   { id: 0, name: "No meeting room required", value: null },
//   { id: 1, name: "Elbe", value: "Elbe" },
//   { id: 2, name: "Donau", value: "Donau" },
//   { id: 3, name: "Isar", value: "Isar" },
//   { id: 4, name: "Main", value: "Main" },
//   { id: 5, name: "Lech", value: "Lech" },
//   { id: 6, name: "Regen", value: "Regen" },
//   { id: 7, name: "Vils", value: "Vils" },
//   { id: 8, name: "Alz", value: "Alz" },
//   { id: 9, name: "Inn", value: "Inn" },
//   { id: 10, name: "Laber", value: "Laber" },
//   { id: 11, name: "Alishan", value: "Alishan" },
//   { id: 12, name: "Hehuanshan", value: "Hehuanshan" },
//   { id: 13, name: "OldTown", value: "OldTown" },
//   { id: 14, name: "Yangmingshan", value: "Yangmingshan" },
//   { id: 15, name: "Yushan", value: "Yushan" },
// ]);

onMounted(() => {
  fetchRooms();
});

const branchOffices = ref([
  { id: 1, name: "Deggendorf, CDE", value: "Deggendorf" },
  { id: 2, name: "Plzen, CCZ", value: "Plzen" },
  { id: 3, name: "Brno, CCZ", value: "Brno" },
  { id: 4, name: "Taipei City, CTW", value: "Taipei City" },
  { id: 5, name: "San Diego, CUS", value: "San Diego" },
  { id: 6, name: "West Burleigh, CAU", value: "West Burleigh" },
  { id: 7, name: "Tokyo, CJP", value: "Tokyo" },
  { id: 8, name: "Shanghai, CCN", value: "Shanghai" },
  { id: 9, name: "Seongnam, CKR", value: "Seongnam" },
  { id: 10, name: "London, CUK", value: "London" },
  { id: 11, name: "Paris, CFR", value: "Paris" },
  { id: 12, name: "Ravensburg, RTS", value: "Ravensburg" },
  { id: 13, name: "Wittlich, RTS", value: "Wittlich" }
]);

const meetinRoomReservedDate: Ref<string | undefined> = ref();
const showReservedMessage = ref(false);
const reservedMessageSeverity = ref("success");
const reservedMessageText = ref("Meetingroom is available")
const additionalAttendees = ref<any>([]);
const userQuery = ref<any>("");
const responsibleQuery = ref<any>("");
const usersFound = ref<any[]>([]);
const userQueryTimer = useDelayTimer();
const responsibleEmployee = ref<any>("");

const emits = defineEmits(['visitCreated'])

const gteCheckInTime = (checkout: Array<string>) => checkout.every((x, index) => { return new Date(x) >= new Date(state.value.scheduledCheckInTime[index]); });

const rules = {
  quickVisitors: {
    $each: helpers.forEach({
      firstName: { required },
      lastName: { required },
    })
  },
  Company: { required: requiredIf(() => isCompanyVisitor.value) },
  Postalcode: { required: requiredIf(() => isCompanyVisitor.value) },
  Street: { required: requiredIf(() => isCompanyVisitor.value) },
  City: { required: requiredIf(() => isCompanyVisitor.value) },
  country: { required },
  scheduledCheckInOutDates: { required },
  scheduledCheckInTime: { required },
  scheduledCheckOutTime: { required, gteCheckInTime: helpers.withMessage(() => ` must be greater than or equal to Checkin-Time`, gteCheckInTime), $lazy: true },
  //description: { required },
}
const v$ = useVuelidate(rules, state, { $lazy: true });

//watch area
watch(() => props.visible, async (nv: any) => {
  console.log("VISIBLE: ", nv);
  if (nv != undefined) {
    dialogVisible.value = nv;
  }
}, { deep: true, immediate: true });

watch(() => dialogVisible.value, (nv) => {
  if (nv != null && !nv) {
    showReservedMessage.value = false;
    selectedRoom.value = null;
    v$.value.$reset();
    state.value.scheduledCheckInOutDates = Array<Date>(new Date());
    state.value.scheduledCheckInTime = [new Date()];
    state.value.scheduledCheckOutTime = [new Date(Date.now() + 1 * (60 * 60 * 1000))];
    state.value.Company = "";
    isCompanyVisitor.value = true;
    state.value.quickVisitors = [{ id: 1, firstName: "", lastName: "" }];
    props.onHide(true);
  }
})

watch(() => scheduledStartEndDates.value, () => {
  state.value.scheduledCheckInOutDates = [];
  for (let date = new Date(scheduledStartEndDates.value[0]); date <= scheduledStartEndDates.value[1]; date.setDate(date.getDate() + 1)) {
    const cloneDate = new Date(date);
    state.value.scheduledCheckInOutDates.push(cloneDate);
  }
})

watch(() => scheduledStartEndDate.value, () => {
  state.value.scheduledCheckInOutDates = [];
  state.value.scheduledCheckInOutDates.push(scheduledStartEndDate.value);
})

let scheduledCheckInOutDatesCopy = state.value.scheduledCheckInOutDates.slice();
watch(() => state.value.scheduledCheckInOutDates, () => {
  //console.log("watch scheduledCheckInOutDates ", state.value.scheduledCheckInOutDates);
  let missingIndexes = null;
  if (scheduledCheckInOutDatesCopy.length > state.value.scheduledCheckInOutDates.length && state.value.scheduledCheckInOutDates.length >= 1) {
    // date was removed
    missingIndexes = findMissingIndexes(state.value.scheduledCheckInOutDates, scheduledCheckInOutDatesCopy);
    if (missingIndexes) {
      missingIndexes.forEach((index: number) => {
        state.value.scheduledCheckInTime.splice(index, 1);
        state.value.scheduledCheckOutTime.splice(index, 1);
        console.log("removed index ", index);
      });
    }
  }
  else if (scheduledCheckInOutDatesCopy.length < state.value.scheduledCheckInOutDates.length) {
    // date was added
    state.value.scheduledCheckInTime.push(new Date(state.value.scheduledCheckInTime[0]));
    state.value.scheduledCheckOutTime.push(new Date(state.value.scheduledCheckOutTime[0]));
  }
  scheduledCheckInOutDatesCopy = state.value.scheduledCheckInOutDates.slice();
});

//functions area
async function dispatchCreateVisitAsync(CreateVisitRequest: QuickCreateVisitRequest) {
  console.log("Visit Create: ", CreateVisitRequest);
  console.log("Time:", state.value.scheduledCheckInOutDates ? [0] : 0)
  await v$.value.$reset();
  await v$.value.$validate(); // checks all inputs
  if (v$.value.$error) {
    return;
  }
  let res = await VisitService.postApiV1VisitQuickCreate(CreateVisitRequest);

  if (res.status == ScreeningStatus.LISTED) {
    confirm.require({
      group: "QuickCreateScreeningStatus",
      header: "Negative Screening Status",
      icon: "pi pi-info-circle",
      acceptClass: "hidden",
      message: `The visitor screening result was negativ. 
      Please provide further information about the visitor (e.g. address)
      and screen again manually or contact ECC (export-control@congatec.com).`,
      rejectLabel: "Close",
      accept: async () => {
      },
    });
  }
  dialogVisible.value = false;
}

function setActiveTab(tabName: string) {
  state.value.scheduledCheckInOutDates = [];
  scheduledStartEndDates.value = [];
  activeTab.value = tabName;
}

function onTabChange(e: any) {
  setActiveTab(e.item.label)
}


async function checkMeetingRoomAvailability() {
  if (state.value.scheduledCheckInOutDates && state.value.scheduledCheckInOutDates.length > 0 && selectedRoom.value) {
    let isFree = true;
    showReservedMessage.value = false;
    for (var i = 0; i < state.value.scheduledCheckInOutDates.length; i++) {
      var date = state.value.scheduledCheckInOutDates[i]
      let fromTime = new Date(state.value.scheduledCheckInTime[i]);
      let toTime = new Date(state.value.scheduledCheckOutTime[i]);
      let tmpDate = new Date(date);
      let tmpFromDateTime = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), fromTime.getHours(), fromTime.getMinutes())
      let tmpToDateTime = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), toTime.getHours(), toTime.getMinutes())
      console.log("tmpFromDateTime: ", tmpFromDateTime);

      let response = await MicrosoftGraphService.getApiV1MicrosoftGraph(tmpFromDateTime.toISOString(), tmpToDateTime.toISOString(), selectedRoom.value)
      if (response.events && response.events[0]?.start) {
        isFree = false;
        meetinRoomReservedDate.value = response.events[0].start;
        break;
      }
    }

    if (isFree) {
      reservedMessageSeverity.value = "success";
      showReservedMessage.value = true;
      reservedMessageText.value = "Meeting Room is available";
    } else {
      reservedMessageSeverity.value = "error";
      showReservedMessage.value = true;
      var utc = new Date(meetinRoomReservedDate.value || "");
      // var offset = utc.getTimezoneOffset();
      // var local = new Date(utc.getTime() - offset * 60000);
      reservedMessageText.value = "Meeting Room is reserved on " + utc.toLocaleString();
    }
  }
}

watch(userQuery, async () => {
  delay(userQueryTimer, async () => {
    if (!userQuery.value) {
      usersFound.value = [];
      return;
    }

    let query = userQuery.value;

    usersFound.value = (await UserService.postApiV1UserFindUser(query)).users || [];

    for (let user of usersFound.value) {
      if (user.email) {
        user.identifier = user.email;
      } else {
        user.identifier = `${user.firstName} ${user.lastName}`
      }
    }
  });

});

watch(responsibleQuery, async () => {
  delay(userQueryTimer, async () => {
    if (!responsibleQuery.value) {
      usersFound.value = [];
      return;
    }

    let query = responsibleQuery.value;

    usersFound.value = (await UserService.postApiV1UserFindUser(query)).users || [];

    for (let user of usersFound.value) {
      if (user.email) {
        user.identifier = user.email;
      } else {
        user.identifier = `${user.firstName} ${user.lastName}`
      }
    }
  });

});

let initialRooms: ListPlacesResponse = { rooms: [] };
async function fetchRooms() {
  initialRooms = await MicrosoftGraphService.getApiV1MicrosoftGraphPlacesList();
  if (branchOffice.value != null) {
    rooms.value = initialRooms?.rooms?.filter((room: Room) => room.address === null || room.address?.city == null || room.address?.city === branchOffice.value);
  }
  else {
    rooms.value = initialRooms.rooms?.slice();
  }
}

const convertToUTCDateString = (strDate: string) => {
  var date = new Date(strDate);
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0, 0)).toISOString();
}

const addIndex = () => {
  visitorCount.value = state.value.quickVisitors[state.value.quickVisitors.length - 1].id + 1;
  state.value.quickVisitors.push({ id: visitorCount.value, firstName: "", lastName: "" });
  console.log("indexes: ", state.value.quickVisitors);
}

const removeIndex = (index: number) => {
  if (state.value.quickVisitors.length == 1)
    return;

  state.value.quickVisitors = state.value.quickVisitors.filter((x) => x.id != index);
  //visitorCount.value = indexes.value.length;
  console.log("indexes: ", state.value.quickVisitors);
}

function getCountriesList() {
  const result: any[] = []
  const countryCodes = Object.keys(countries);
  countryCodes.map(code => result.push({ code: code, name: (countries as any)[code].name }));
  return result.sort((p1, p2) => (p1.name > p2.name) ? 1 : (p1.name < p2.name) ? -1 : 0);
}

const changeCountry = (event: any) => {
  visitStore.setCountry(event.value);
}

const changeBranchOffice = (event: any) => {
  visitStore.setBranchOffice(event.value);
  filterMeetingRooms(event);
}

const addCheckInTime = () => {
  state.value.scheduledCheckInTime.push(new Date());
  console.log("addCheckInTime ", state.value.scheduledCheckInTime);
}

const removeCheckInTime = (index: number) => {
  state.value.scheduledCheckInTime.splice(index, 1);
  console.log("removeCheckInTime ", state.value.scheduledCheckInTime);
}

function findMissingIndexes(arr: any[], fullArr: any[]): number[] {
  const missingIndexes: number[] = [];
  fullArr.forEach((item, index) => {
    if (!arr.includes(item)) {
      missingIndexes.push(index);
    }
  });
  return missingIndexes;
}

const filterMeetingRooms = (event: any) => {
  rooms.value = initialRooms?.rooms?.filter((room: Room) => room.address === null || room.address?.city == null || room.address?.city === event.value);
  // if(rooms.value?.length == 0) {
  //   rooms.value = initialRooms?.rooms?.filter((room: Room) => room.address === null || room.address?.city == null);
  // }
}

</script>
  
<style >
.w-exact {
  width: 359px;
}

.userbutton .pi {
  font-size: 2rem;
}


.input-invalid {
  border: 1px solid var(--primary-color);
}

.input-invalid:hover {
  border: 1px solid var(--primary-color) !important;
}
</style>
<style scoped>
/* It is totally fine to have two style blocks in one component and it works!
https://vuejs.org/api/sfc-css-features.html#mixing-local-and-global-styles */
span {
  color: var(--primary-color);
  font-weight: 600;
}
</style>
